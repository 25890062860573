import React,{useState} from 'react';
import './style.scss';
import { NavHashLink  } from 'react-router-hash-link';
import { scrollWidthOffset } from '../../utils/scrollWidthOffset';
import Button from '../../components/button';
import {Link} from 'react-router-dom';

const Header = () => {
 
    const [mobileNav, setMobileNav] = useState(false);
    
   const activateMobileNav = () => {
    setMobileNav((prev)=>!prev)
}
    const closeMobileNav = ()=> {
        setMobileNav(false)
        const burger = document.querySelector('#checkbox4')
        burger.checked = false
    }
    return (
        <header className={mobileNav?'header-mobile':'header'}>
            <div className={mobileNav?'header-wrap-mobile':'header-wrap container'}>
            <div className='header-logo'>
            <Link to='/#home' className='header-link'><img src='./images/logo.svg' alt='Logo'/></Link>
            </div>

            <nav className={mobileNav?'navigation-mobile':'navigation'}>
                <ul className={mobileNav?'navigation-wrap-mobile':'navigation-wrap'}>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#home' smooth>Главная</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#about'smooth >Обо мне</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#services' smooth>Услуги</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#howwework' smooth>Как мы работаем</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#awards' smooth>Награды</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#reviews' smooth>Отзывы</NavHashLink></li>
                    <li className={mobileNav?'navigation-link-mobile':'navigation-link'}><NavHashLink
                     className='navigation-item'
                     onClick={closeMobileNav}
                     activeClassName ={'navigation-item-active'}
                     scroll={el => scrollWidthOffset(el)}
                     to='/#contacts' smooth>Контакты</NavHashLink></li>
                     {mobileNav? <li><Button mobileNav onClick = {closeMobileNav} anchor/></li> : null}
                </ul>
            </nav>
            <div className='navigation-mobileButton'>
                <input type="checkbox" id="checkbox4" onClick={activateMobileNav} className="checkbox4 visuallyHidden" />
                <label htmlFor="checkbox4">
                <div className="hamburger hamburger4">
                    <span className="bar bar1"></span>
                    <span className="bar bar2"></span>
                    <span className="bar bar3"></span>
                    <span className="bar bar4"></span>
                    <span className="bar bar5"></span>
                </div>
            </label>
            </div>
            </div>
        </header>
    )
}

export default Header;