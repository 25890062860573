import './assets/scss/App.scss';
import Header from './layout/header';
import Footer from './layout/footer';
import Content from './layout/content';
import {  Router } from 'react-router-dom';
import { createBrowserHistory } from "history";

const customHistory = createBrowserHistory();

function App() {
  return (
    <Router history = {customHistory}>
      <div className="App">
        <Header />         
        <Content />
        <Footer />
      </div>
    </Router>

  );
}

export default App;
