import React from 'react';
import './style.scss';
import { Map } from '../../components/Map/index';
//import { Link } from 'react-router-dom';

const Footer = () => {
    //const geoPosition = '50.40851692206552, 30.54384643057184'  
   // https://static.maps.2gis.com/1.0?s=390x390&pt=50.40851692206552,30.54384643057184~s:l

    return (
        <footer id='contacts' className='container'>
            <div className='footer'>
            <div className='footer-wrap'>
            <div className='footer-titleWrap'>
                <h2>Конта<span className='spanTitle'>кты</span></h2>
                <div className='footer-line'/>
            </div>
                <div className='footer-iconsWrap'>
                <div className='footer-connect'>
                    <p className='footer-text'>Связаться со мной</p>
                    <a href="https://wa.me/380676376131?text=Здравствуйте,%20Надежда.%20" className='footer-icons'><img src='./images/Subtract.svg' alt='' /></a>
                    <a href='viber://chat?number=%2B380676376131' className='footer-icons'><img src='./images/Group.svg' alt='' /></a>
                    <a href='https://join.skype.com/invite/lBn93TnOn5F4' className='footer-icons'><img src='./images/Vector(3).svg' alt='' /></a>
                    <a href='tg://resolve?domain=nadia_yerchenko' className='footer-icons'><img src='./images/Vector(2).svg' alt='' /></a>
                </div>
                <div className='footer-connect'>
                    <p className='footer-text'>Следите  за соцсетями</p>
                    <a href='https://www.instagram.com/nadia_yerchenko/' className='footer-icons'><img src='./images/Group558.svg' alt='' /></a>
                    <a href='https://www.facebook.com/yerchenko/' className='footer-icons'><img src='./images/Vector(4).svg' alt='' /></a>
                    <a href='/' className='footer-icons'><img src='./images/Vector(5).svg' alt='' /></a>
                </div>
                </div>
                <div className='footer-contactsWrap'>
                    <div className='footer-contacts'>
                        <p className='footer-text'>Адреса:</p>
                        <p className='footer-content'>Киев, ул. Драгомирова 17, Delab</p>  
                    </div>
                    <a href="mailto:info@yerchenko.com" className='footer-contacts'>
                        <p className='footer-text'>E-Mail:</p>
                        <p className='footer-content'> info@yerchenko.com</p>  
                    </a>
                    <a href='tel:+380676376131'  className='footer-contacts'>
                        <p className='footer-text'>Phone:</p>
                        <p className='footer-content'> +38(067)637-61-31</p>  
                    </a>
                </div>
            </div>
            <div className='footer-map'>
                {/* <img src='https://static.maps.2gis.com/1.0?s=390x390&pt=50.40851692206552,30.54384643057184~s:l&z=14' alt='map'/> */}
                <Map />
            </div>
            </div>
        </footer>
    )
}

export default Footer;
//href="whatsapp://+380676376131"
//href="https://wa.me/380676376131?text=Здравствуйте,%20Надежда.%20"