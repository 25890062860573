import React from 'react';
import './style.scss';
import Button from '../button';
import PropTypes from 'prop-types';

const Loader = ({message,button,mess}) =>{
    return (
        <div className='loader'>
            <div className='loader-wrapper'>
                <figure className='loader-figure'>
                    <img src='./images/clock.svg' alt='loader' />
                </figure>
                <h3 className='loader-text'>{message}</h3>
                {button? <div className='loader-button'><Button message={mess? {mess}:'Все услуги'} to={'/#services'}/></div> : null}    
            </div>
        </div>
    )
};

Loader.propTypes = {
    message:      PropTypes.string,
    button:    PropTypes.bool,

}

export default Loader;