import DG from '2gis-maps';
import {useEffect} from 'react';
import MapWrapper from './mapWrapper';

export const Map = () => {
  //const [mapInstance, setMapInstance] = useState();
    useEffect(() => {
      let map = DG.map('map', {
        'center': [50.40851692206552, 30.54384643057184],
        'zoom': 13,
        'zoomControl': false,
        'fullscreenControl': false
    });
    let myIcon = DG.icon({
      iconUrl: './images/marker.svg',    
      iconSize: [34, 47],
  });
    DG.marker([50.40851692206552, 30.54384643057184],{icon: myIcon}).addTo(map);

        // Destroy the map on unmounted
        return () => map && map.destroy();
    }, []);

    return (
      <div id='map'>
        <MapWrapper />
      </div>
    );
  };
