import { lazy } from 'react';

const routes = [
    {
        path:       ['/', '/#home'],
        exact:      true,
        components: lazy(
            () => import('../pages/Home')
        ),
    },
    {
        path:       ['/personal'],
        components: lazy(
            () => import('../pages/PersonalService/index.js')
        ),
    },
    {
        path:       ['/group'],
        components: lazy(
            () => import('../pages/GroupService/index.js')
        ),
    },
    {
        path:       ['/online'],
        components: lazy(
            () => import('../pages/OnlineService/index.js')
        )
    },
    {
        path:       [''],
        components: lazy(
            
            () => import('../pages/NotFound/index.js')
        )  
    }

];

export default routes;