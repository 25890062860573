import React, { Suspense } from 'react';
import { Route, Switch } from "react-router-dom";
import routes from '../../router';
import Loader from '../../components/loader';


const Content = () => {
    return(       
    <main >
            <Suspense fallback={ <Loader message='Загружаемся...' /> }>
                <Switch>  
                    {
                        routes.map( ( route, index ) => (
                            <Route
                                key={ index }
                                path={ route.path }
                                exact={ route.exact }
                                render={ props => (
                                    <route.components { ...props } routes={ route.routes }/>
                            )}/>))
                    }
                </Switch>  
            </Suspense>
    </main>
    )
};

export default Content;