import React from 'react';
import './style.scss';
import PropTypes from 'prop-types';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { scrollWidthOffset } from '../../utils/scrollWidthOffset';
import { useLocation }from 'react-router-dom';

const Button = ({form,cardClass,onClick,message,anchor,inModal,mobileNav,to,submit,disabling}) => {
    let locate = useLocation()

    if(locate.pathname==='/online'){
        locate.pathname='/'
    }

    return (
        <div className={inModal?'button-cardWrap':mobileNav?'button-wrap-nav':'button-wrap'}>
            {anchor? <NavLink scroll={el => scrollWidthOffset(el)}
                            to='/#form' smooth 
                            className={form?'button-form':cardClass?'button-card':'button-btn'} 
                            onClick={onClick}>Заказать консультацию</NavLink>:
                   !to? <button className={disabling?'button-disabled':form?'button-form':cardClass?'button-card':'button-form'}
                            submit={submit? 'submit':null} disabled={disabling?'disabled':null}
                            onClick={onClick}>{message? message:'Заказать консультацию'}</button>
                            :
                        <NavLink scroll={el => scrollWidthOffset(el)}
                            to={to} smooth 
                            className={form?'button-form':cardClass?'button-card':'button-btn'} 
                            onClick={onClick}>{message}</NavLink>}
        </div>
    )
}
Button.propTypes = {
    onClose:      PropTypes.func,
    title:        PropTypes.string,
    anchor:         PropTypes.bool,
    to:             PropTypes.string,
    inModal:        PropTypes.bool,
    mobileNav:      PropTypes.bool,
    cardClass:      PropTypes.bool,
    form:           PropTypes.bool,
    submit:         PropTypes.bool
};

export default Button;